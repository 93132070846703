.pageLayout {
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  -webkit-font-smoothing: antialiased;

  .content {
    flex-grow: 1;
    width: 100%;
    position: relative;
  }
}
